import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-ui-checkbox',
    templateUrl: './ui-checkbox.component.html',
    styleUrls: ['./ui-checkbox.component.scss'],
})
export class UiCheckboxComponent {
    @Input() disabled!: boolean;
    @Input() value!: boolean;
    @Output() valueChange = new EventEmitter<boolean>();

    constructor() {}

    toggleChange(event: any) {
        this.valueChange.emit(event);
    }
}
