<input
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="placeholder"
    inputmode="text"
    [type]="password ? 'password' : 'text'"
    [ngModel]="value"
    (ngModelChange)="inputText($event)"
    (keyup.enter)="clickEnterEv()"
    [maxlength]="maxLength"
/>
