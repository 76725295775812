import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NotWelcomeGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {
  }

  async canActivate(): Promise<boolean> {
    const welcome = await localStorage.getItem('welcome');
    if (welcome) {
      await this.router.navigate(['/system']);
      return false;
    } else {
      return true;
    }
  }

}
