<div class="container">
    <app-header [shadow]="false">
        <div slot="start">
            <app-back-button [func]="back()"></app-back-button>
        </div>
    </app-header>

    <div>
        <div class="padding-20">
            <h4 class="title">Регистрация</h4>
            <div class="input-container">
                <app-sz-input
                    [type]="'phone'"
                    [(value)]="phone"
                    [title]="'Телефон'"
                    [placeholder]="'Ваш телефон'"
                ></app-sz-input>
            </div>
            <div class="input-container">
                <app-sz-input
                    [type]="'oneword'"
                    [(value)]="name"
                    [title]="'Имя'"
                    [placeholder]="'Ваше имя'"
                ></app-sz-input>
            </div>

            <div class="body_secondary_circe description" (click)="toSignIn()">
                Уже есть аккаунт? <span class="accent">Войти</span>
            </div>

            <div class="fixed-button bottom-safe-area">
                <div class="button-container">
                    <app-button (btnClick)="sendPhone()" [class]="'primary'" [disabled]="!vaild() || loading || !agreed"
                        >Зарегистрироваться
                    </app-button>

                  <div class="sign-up-agree-container">
                    <app-ui-checkbox class="sign-up-agree-container_checkbox"
                      [value]="agreed"
                      (valueChange)="agreed = $event"
                    ></app-ui-checkbox>
                    <div class="caption_circe sign-up-agree-container_text">
                      Я принимаю условия <span (click)="goLegal()">Пользовательского соглашения</span> и даю согласие на обработку персональных данных на условиях <span (click)="goPrivacy()">Политики в отношении обработки персональных данных</span>
                    </div>
                  </div>

                </div>
            </div>
        </div>
    </div>
</div>
