import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-sz-input',
    templateUrl: './sz-input.component.html',
    styleUrls: ['./sz-input.component.scss'],
})
export class SzInputComponent implements OnInit {
    @Input() title?: string;
    @Input() placeholder = '';
    @Input() readonly: boolean = false;
    @Input() type?:
        | 'text'
        | 'number'
        | 'currency'
        | 'textarea'
        | 'readonly'
        | 'phone'
        | 'code'
        | 'oneword'
        | 'date'
        | 'card'
        | 'password';
    @Input() value: any = '';

    @Input() underTitle?: string;

    @Input() disabled = false;

    @Input() rows: number = 3;

    @Input() prefix: string = '';

    @Input() valid: boolean = true;

    @Input() maxLength: number = 500;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Output() enter: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    inputEv(ev: any) {
        this.valueChange.emit(ev);
    }

    clickEnter() {
        this.enter.emit(true);
    }
}
