import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
    @Input() placeholder = '';
    @Input() value = '';
    @Input() disabled = false;
    @Input() password = false;

    @Input() maxLength: number = 500;

    @Output() inputEv: EventEmitter<any> = new EventEmitter<any>(false);
    @Output() clickEnter: EventEmitter<any> = new EventEmitter<any>(false);

    constructor() {}

    ngOnInit() {}

    inputText(event: any) {
        this.inputEv.emit(event);
    }

    clickEnterEv() {
        this.clickEnter.emit(true);
    }
}
