<div [ngSwitch]="type" class="sz-input">
    <label class="overline_circe">
        {{ title }}

        <ng-container *ngSwitchCase="'currency'">
            <app-input-currency
                [value]="value"
                [prefix]="prefix"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
            ></app-input-currency>
        </ng-container>

        <ng-container *ngSwitchCase="'textarea'">
            <app-input-textarea
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                [readonly]="readonly"
                [rows]="rows"
                (inputEv)="inputEv($event)"
                [maxLength]="maxLength"
            ></app-input-textarea>
        </ng-container>

        <ng-container *ngSwitchCase="'text'">
            <app-input-text
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
                [maxLength]="maxLength"
            ></app-input-text>
        </ng-container>

        <ng-container *ngSwitchCase="'oneword'">
            <app-input-one-word
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
            ></app-input-one-word>
        </ng-container>

        <ng-container *ngSwitchCase="'number'">
            <app-input-number
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
            ></app-input-number>
        </ng-container>

        <ng-container *ngSwitchCase="'readonly'">
            <app-input-readonly
                [style.overflow]="'hidden'"
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (output)="inputEv($event)"
            ></app-input-readonly>
        </ng-container>

        <ng-container *ngSwitchCase="'phone'">
            <app-input-phone
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
            ></app-input-phone>
        </ng-container>

        <ng-container *ngSwitchCase="'code'">
            <app-input-code
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
            ></app-input-code>
        </ng-container>

        <ng-container *ngSwitchCase="'card'">
            <app-input-card
                    [value]="value"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    (inputEv)="inputEv($event)"
                    (clickEnter)="clickEnter()"
            ></app-input-card>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
            <app-input-date
                [value]="value"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (valueChange)="inputEv($event)"
            ></app-input-date>
        </ng-container>

        <ng-container *ngSwitchCase="'password'">
            <app-input-text
                [value]="value"
                [password]="true"
                [placeholder]="placeholder"
                [disabled]="disabled"
                (inputEv)="inputEv($event)"
                (clickEnter)="clickEnter()"
                [maxLength]="maxLength"
            ></app-input-text>
        </ng-container>
    </label>

    <span class="bar" [class.error-input]="!valid"></span>
    <span *ngIf="underTitle" [class.error-span]="!valid" class="under-title caption_rubik">{{ underTitle }}</span>
</div>
