<textarea
    class="body_rubik font_regular"
    [disabled]="disabled"
    [placeholder]="placeholder"
    inputmode="text"
    [value]="value"
    [(ngModel)]="value"
    [rows]="rows"
    [readOnly]="readonly"
    (input)="inputTextarea($event)"
    (paste)="inputTextarea($event)"
    [maxLength]="maxLength"
></textarea>
